import { Box, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  ConversionTrackerStorage,
  conversionTrackerStorageKey,
} from "../hooks/use-conversion-tracker";
import { useLocalStorage } from "../hooks/use-local-storage";
import { Product } from "../pages/api/plans";
import { ButtonCallbackProps, PricingCard } from "./pricing-card";
import { promotionCodes } from "../configuration/promotion/promotion-codes";
import { LandingLocale } from "../localization/landing";

interface Props {
  promotionCodeId?: string;
  products: Product[];
  buttonCallback: (props: ButtonCallbackProps) => void;
  l: LandingLocale;
}

export default function PricingSwitch(props: Props) {
  const { storageData } = useLocalStorage<ConversionTrackerStorage>(
    conversionTrackerStorageKey
  );

  const [discountPercentage, setDiscountPercentage] = useState<number>();

  const isPoland = storageData?.referrer?.includes("talkio.pl");
  const [billingTypeChecked, setBillingTypeChecked] = useState(false);

  useEffect(() => {
    if (props.promotionCodeId) {
      const percentage = promotionCodes.find(
        (p) => p.code === props.promotionCodeId
      )?.percentage;

      if (typeof percentage === "number") {
        setDiscountPercentage(percentage);
      }
    }
  }, [props.promotionCodeId]);

  useEffect(() => {
    if (isPoland) {
      setBillingTypeChecked(true);
    }
  }, [isPoland]);

  const products = props.products.filter((p) => {
    const monthly =
      p.price.type === "recurring" && p.price.recurring?.interval === "month";
    const yearly =
      p.price.type === "recurring" && p.price.recurring?.interval === "year";
    const oneTime = p.price.type === "one_time";

    return monthly || yearly || oneTime;
  });

  const displayPrice = (
    amount: number,
    currency: string,
    priceType: string,
    interval?: string
  ) => {
    let curLabel = currency.toUpperCase();
    if (currency === "usd") {
      curLabel = "$";
    } else if (currency === "eur") {
      curLabel = "€";
    }

    const divider = priceType === "one_time" ? 6 : interval === "year" ? 12 : 1;

    return `${curLabel}${amount / divider / 100}`;
  };

  const subscriptionPaymentProviders = ["Visa", "MasterCard", "PayPal"];

  const onetimePaymentProviders = [
    "Visa",
    "MasterCard",
    "PayPal",
    "WeChat",
    "Przelewy24",
    "Giropay",
  ];

  return (
    <Box>
      <Flex
        justifyContent="center"
        alignItems={"center"}
        flexDir={{ base: "column", md: "row" }}
      >
        {products.map((p, i) => (
          <PricingCard
            key={i}
            title={p.name}
            description={
              p.price.type === "one_time"
                ? props.l["pricing.description.perMonthBilledOnce"]
                : p.price.recurring?.interval === "year"
                ? props.l["pricing.description.perMonthBilledYearly"]
                : props.l["pricing.description.perMonth"]
            }
            price={displayPrice(
              p.price.unit_amount,
              p.price.currency,
              p.price.type,
              p.price.recurring?.interval
            )}
            discountPrice={
              typeof discountPercentage === "number"
                ? displayPrice(
                    (p.price.unit_amount * (100 - discountPercentage)) / 100,
                    p.price.currency,
                    p.price.type,
                    p.price.recurring?.interval
                  )
                : undefined
            }
            discountPercentage={discountPercentage}
            submitLink={p.checkoutLink}
            buttonCallback={props.buttonCallback}
            productId={p.id}
            submitText={
              p.price.type === "one_time"
                ? props.l["pricing.button.buyNow"]
                : props.l["pricing.button.startFreeTrial"]
            }
            features={[
              p.price.type === "one_time"
                ? props.l["pricing.features.refund"]
                : props.l["pricing.features.freeTrial"],
              props.l["pricing.features.supportsLanguages"],
              props.l["pricing.features.voiceConversations"],
              props.l["pricing.features.instantFeedback"],
              props.l["pricing.features.pronunciationPractice"],
              props.l["pricing.features.interactiveWordbook"],
              props.l["pricing.features.speechRecognition"],
              props.l["pricing.features.extraFeatures"],
            ]}
            colorScheme={p.price.type === "one_time" ? "white" : "white"}
            paymentProviders={
              p.price.type === "one_time"
                ? onetimePaymentProviders
                : subscriptionPaymentProviders
            }
          />
        ))}
      </Flex>
    </Box>
  );
}
